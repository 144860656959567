import React, { Component } from 'react';
import { BigNumber, ethers } from 'ethers';

import { getChainIdFromShortName, getProviderWithChainId, initProvider, MiningPoolAbi, nftAbi, getChainCurrencySymbolWithChainId, tokenAbi, zeroAddress, getChainSymbol } from '../../utils/config';
import { Contract, Provider } from 'ethers-multicall';
import moment from 'moment-timezone';
import { eventBus, isAddress } from '../../utils/tool';
import { convertIpfsUrlToGatewayUrl, getIpfsText, getRemoteUrlContent } from '../../utils/ipfs';
import { connect } from "react-redux"
import store from '../../store';
import SweetAlert2 from 'react-sweetalert2';
import { withTranslation } from 'react-i18next';

import copy from 'copy-to-clipboard';
// const confetti = require('canvas-confetti');
// import confetti from 'canvas-confetti'


// import '@sweetalert2/theme-dark/dark.scss';
import "../Staking/swal-dark.css"

import IpfsPanel from '../Staking/components/IpfsPanel/index'
import InviteConfirmModal from '../Staking/components/InviteConfirmModal/index'
import WhitelistModal from '../Staking/components/WhitelistModal/index'
import UserListTable from '../Staking/components/UserListTable/index'
import { Modal, Form } from 'react-bootstrap';
import SuccessModal from './SuccessModal';
import MintModal from './MintModal';
import NftCard from './nftCard';

moment.tz.setDefault('UTC') // global style

class NFTConsoleOne extends Component {
    state = {
        data: {},
        tabData: [],
        tabContent: [],
        features: [],
        chain: 'bsc', // 公链
        contract: '0x3dC442DC8d82F5732209947779656fcaDe9d3b3F', // 矿池地址
        account: '',
        ipfsInfo: {},
        stakeTokenInfo: {},
        rewardTokenInfo: {},
        poolInfo: {},
        showStakeModal: false,
        showTransferOwnershipModal: false,
        mintLoading: false,
        stakeAmountInput: 0, // 质押数量
        stakeMax: false, // 点了max按钮
        swalProps: {},
        reawardLoading: false,// 领取奖励的loading
        currentProvider: null,
        showUnstakeModal: false, // 展示解除质押按钮
        stopRewardLoading: false, // 停止质押的loading
        enableForceWithdrawLoading: false, // 强制解除质押的loading
        showTimeDetail: false, // 是否展示时间详情
        showIpfsPanel: false, // 展示ipfs信息
        countdown: {
            type: 0,
            ct: '00:00:00' // 时 分 秒
        },
        showInviteConfirmModal: false, // 展示确认邀请modal
        showWhitelistModal: false, // 设置白名单
        params: {},
        urlPath: '', // 当前的路径 主机+域名+#+hash
        invitorList: [], // 当前用户的邀请人列表信息
        invitorTotalCount: 0,
        perPage: 10,
        isAdmin: false, // 是否是管理员
        stakeUserList: [], // 质押用户列表
        adminInfo: {}, // 管理员信息,
        networkName: '',
        tmpNewOwner: '',
        tmpNewOwnerLoading: false,
        notShowList: [

        ], // 不显示交易对的列表
        showMintModal: false, // 展示铸造modal  
        nftInfo: {}, // nft信息
        mintSuccessInfo: {
            id: 0,
            url: '',
            name: '',
            description: '',
            show: false
        },
        myNftList: [],
        myNftCount: 0,
        myNftPage: 0,
        myNftPerPageCount: 10
    }

    async initProvider() {
        let provider = null
        const chainId = this.state.chainId
        if (window.ethereum) {
            const hexChainId = await window.ethereum.request({ method: 'eth_chainId' })
            const cid = parseInt(BigNumber.from(hexChainId).toString())
            // 如果chainId能对上再继续
            if (chainId === cid) {
                // 直接走获取provider的逻辑
                provider = new ethers.providers.Web3Provider(window.ethereum)
            }
        }
        if (!provider) {
            // 直接走获取provider的逻辑
            provider = getProviderWithChainId(chainId)
        }

        return provider
    }

    componentDidMount() {

        this.setState({
            swalProps: {
                show: false,
                title: '',
                text: '',
            }
        })
        eventBus.addListener("walletConnect", this.walletConnect.bind(this))
        // 获取链和合约
        // 根据合约获取ipfs数据, 更新信息
        this.fetchData()




    }



    componentWillUnmount() {
        eventBus.removeListener("walletConnect", this.walletConnect.bind(this))
    }



    walletConnect(v) {
        if (v.account) {
            // 执行初始化操作
            this.setState({
                account: v.account
            }, () => {
                console.log('钱包已连接', this.state.account)
                this.fetchData()
            })
        }
    }
    async fetchData() {
        // 获取url参数
        let currentUrl = window.location.href
        // currentUrl = 'http://nft.starship2069.com/?invitor=0xfa4eb1d1b20bdb867c496133502f23c2ea8fbaee'
        let query = new URL(currentUrl)
        if (query.hash === '') {
            currentUrl = `${query.origin}/#/${query.search}`
            query = new URL(currentUrl)
        }
        const r = query.hash.replace('#/', '').replace('#/', '').split('/')
        // console.log('pssss', query, r)

        let ps = []
        let contract = ''
        if (r.length > 0) {
            ps = r[0].split('?').filter(d => d)
        }
        if (ps.length > 0) {
            contract = ps[0]
        }

        const hrefArr = (query.origin + '/' + query.hash).split('?')

        contract = '0x3dC442DC8d82F5732209947779656fcaDe9d3b3F'
        // console.log('pssss', ps)
        if (ps.length > 0) {
            const params = new URLSearchParams(ps[0])
            // 获取inviter
            const m = {}
            for (const [key, value] of params.entries()) {
                m[key] = value || ''
            }

            // console.log('psssssss', m)
            this.setState({
                params: m
            })
        }




        // 获取代币信息
        // 如果当前钱包和选择的公链一致, 则使用当前的provider
        // 如果不一致, 则自动选择provider
        // const chainId = getChainIdFromShortName(r[0])
        const chainId = 56

        console.log(hrefArr, 'hrefArr')
        this.setState({
            chain: 'bsc',
            contract,
            urlPath: hrefArr[0],
            showStakeModal: false,
            showUnstakeModal: false,
            chainId
        }, async () => {

            const provider = await this.initProvider()

            // 获取矿池信息
            let ethcallProvider = new Provider(provider)
            ethcallProvider = await initProvider(ethcallProvider, chainId)

            const pool = new Contract(contract, nftAbi)

            const calls1 = [
                pool.name(),
                pool.symbol(),
                pool.creator(),
                pool._url(),
                pool.istheSame(),
                pool.isOnlyAdmin(),
                pool.maxSupply(),
                pool.feePerNFT(),
                pool.projectIpfsKey(),
                pool._userMintLimit(),
                pool.openInviterFee(),
                pool.enableInviter(),
                pool.lenOfInvitorRewardPercentList(),
                pool.totalSupply(),
                pool.owner()
            ]


            if (this.state.account) {
                calls1.push(pool._invitor(this.state.account))
                calls1.push(pool._userMintCount(this.state.account))
                calls1.push(pool._reward(this.state.account))
            }

            const res1 = await ethcallProvider.all(calls1)


            const calls2 = [
                pool.feeMode(),
                pool.feeCa()
            ]


            let nftInfo = {
                name: res1[0],
                symbol: res1[1],
                creator: res1[2],
                url: res1[3],
                isTheSame: res1[4],
                isOnlyAdmin: res1[5],
                maxSupply: res1[6],
                feePerNFT: res1[7],
                projectIpfsKey: res1[8],
                userMintLimit: res1[9],
                openInviterFee: res1[10],
                enableInvitor: res1[11],
                lenOfInvitorRewardPercentList: res1[12],
                totalSupply: res1[13],
                owner: res1[14],
                invitor: res1.length > 15 ? (res1[15] !== zeroAddress ? res1[15] : '') : '',
                userMintCount: res1.length > 16 ? res1[16] : 0,
                reward: res1.length > 17 ? res1[17].toString() / 1e18 : 0,
                invitorRewardPercentList: [],
                feeMode: 0,
                feeCa: '',
                feeToken: {}
            }

            try {
                const res2 = await ethcallProvider.all(calls2)
                console.log('res22222', res2)
                nftInfo.feeMode = parseInt(res2[0].toString())
                nftInfo.feeCa = res2[1]
                const feeToken = await this.getTokenInfo(nftInfo.feeCa, provider, chainId, this.state.account, this.state.contract)
                console.log(feeToken, 'feeToken')
                nftInfo.feeToken = feeToken
            } catch (e) {
                console.log('不支持feeMode')
            }

            //  获取邀请比例列表
            if (nftInfo.lenOfInvitorRewardPercentList > 0) {
                const calls2 = []
                for (let i = 0; i < nftInfo.lenOfInvitorRewardPercentList; i++) {
                    calls2.push(pool.invitorRewardPercentList(i))
                }
                const res2 = await ethcallProvider.all(calls2)
                const iList = []
                res2.forEach((item, index) => {
                    iList.push(item.toString() / 100)
                })
                nftInfo.invitorRewardPercentList = iList
            } else {
                nftInfo.invitorRewardPercentList = []
            }


            // 如果开启了邀请功能, 则获取下级列表
            if (nftInfo.enableInvitor) {
                this.getInvitorList(1)
            }

            // this.state.poolInfo.owner.toLowerCase() === this.state.account.toLowerCase()
            const isAdmin = nftInfo.creator?.toLowerCase() === this.state.account?.toLowerCase()

            if (isAdmin) {
                // 获取管理员相关信息
                this.getStakeListForAdmin(1)
            }

            // 获取我已铸造的nft
            this.getMyNftList(1)




            this.setState({
                nftInfo,
                isAdmin,
            })
            store.dispatch({
                type: 'setNftInfo',
                value: nftInfo
            })
            // 获取ipfs信息
            if (nftInfo.projectIpfsKey) {
                getIpfsText(nftInfo.projectIpfsKey)
                    .then(res => {
                        this.setState({
                            ipfsInfo: JSON.parse(res)
                        })
                        store.dispatch({
                            type: 'setMiningInfo',
                            value: JSON.parse(res)
                        })
                    })
            }
        })



    }

    async getMyNftList(page) {
        const provider = await this.initProvider()
        const c = new ethers.Contract(this.state.contract, nftAbi, provider.getSigner())

        let ethcallProvider = new Provider(provider)
        ethcallProvider = await initProvider(ethcallProvider, this.state.chainId)

        const pool = new Contract(this.state.contract, nftAbi)

        const myIdList = await c.tokensOfOwner(this.state.account)
        console.log('myIdList', myIdList)
        const count = myIdList.length
        const offset = this.state.myNftPerPageCount
        const start = (page - 1) * offset





        let myNftList = []
        let myNftCalls = []
        let callIdList = []
        for (let i = 0; i < myIdList.length; i++) {
            if (i >= start && i < start + offset) {
                myNftCalls.push(pool.tokenURI(myIdList[i]))
                callIdList.push(myIdList[i])
            }
        }
        // console.log('nnnnn', myNftCalls)
        if (myNftCalls.length > 0) {
            console.log('nft calls')
            myNftList = await ethcallProvider.all(myNftCalls)
        }

        console.log('mynftlist', myNftList.length, myNftCalls.length)
        if (myNftList.length === myNftCalls.length) {
            const nftList = callIdList.map((item, ind) => {
                return {
                    id: parseInt(item.toString()),
                    url: myNftList[ind]
                }
            })
            console.log(nftList, 'nnnnn')
            this.setState({
                myNftList: nftList,
                myNftCount: count,
                myNftPage: page
            }, () => {
                this.generateMyNftsPagination()
            })
        }

        // // id和nft信息对上了
        // const nftList = myIdList.map((item, ind) => {
        //     return {
        //         id: parseInt(item.toString()),
        //         url: myNftList[ind]
        //     }
        // })

    }

    async getTokenInfo(tokenAddress, provider, chainId, currentWallet, miningContract) {
        let ethcallProvider = new Provider(provider)
        // await ethcallProvider.init()
        ethcallProvider = await initProvider(ethcallProvider, chainId)

        const token = new Contract(tokenAddress, tokenAbi)

        const call = [token.name(), token.symbol(), token.decimals(), token.totalSupply()]
        if (currentWallet) {
            call.push(token.allowance(currentWallet, miningContract))
            call.push(token.balanceOf(currentWallet))
        }
        const res = await ethcallProvider.all(call)
        const decimals = parseInt(res[2].toString())
        const d = Math.pow(10, decimals)

        let allowance = 0
        let balance = 0
        let balanceBigNumber = null
        if (currentWallet) {
            allowance = res[4].toString()
            balance = res[5].toString()
            balanceBigNumber = res[5] // 是一个bigNumber
        }


        return {
            name: res[0],
            symbol: res[1],
            decimals,
            totalSupply: res[3].toString() / d,
            address: tokenAddress,
            allowance,
            balance,
            balanceBigNumber,
        }
    }

    checkNetwork() {
        if (this.state.chainId !== this.props.chainId) {
            this.showMessageModal({
                show: true,
                title: this.props.t('错误'),
                text: this.props.t('当前网络不匹配, 请连接CORE钱包').replace('{net}', this.state.networkName.toUpperCase()),
            })
            return false
        }
        return true
    }

    handleErr(e) {
        let t = ''
        if (e.reason) {
            t = e.reason


        } else {
            t = JSON.stringify(e)
        }
        if (t.indexOf('insufficient balance') > -1) {
            t = this.props.t('余额不足')
        } else if (t.indexOf('insufficient funds') > -1) {
            t = this.props.t('余额不足')
        } else if (t.indexOf('not fee') > -1) {
            t = this.props.t('手续费不足')
        } else if (t.indexOf('Ownable: caller is not the owner') > -1) {
            t = this.props.t('权限不足')
        } else if (t.indexOf('too much') > -1) {
            t = this.props.t('铸造总量已达到上限')
        } else if (t.indexOf('NFT : limit') > -1) {
            t = this.props.t('您的铸造数量已达上限')
        } else if (t.indexOf('not wl')) {
            t = this.props.t('您的邀请者还未铸造NFT')
        } else if (t.indexOf('inviter == msg') > -1) {
            t = this.props.t('您不能邀请自己参与铸造')
        }
        this.showMessageModal({
            show: true,
            title: this.props.t('错误'),
            text: t,
        })
    }


    getShortAddress(address) {
        if (!address) return ''
        return address.slice(0, 4) + "..." + address.slice(address.length - 4);
    }

    async getStakeListForAdmin(page) {
        // 获取质押总量 当前质押总人数 当前质押总算力
        if (!this.state.account) {
            return
        }
        // 获取下级列表
        const p = await this.initProvider()
        let ethcallProvider = new Provider(p)
        ethcallProvider = await initProvider(ethcallProvider, this.state.chainId)
        const pool = new Contract(this.state.contract, nftAbi)

        const calls1 = [pool.getUserCount()]
        const res1 = await ethcallProvider.all(calls1)
        console.log('admin111', res1)
        const adminInfo = {
            userCount: parseInt(res1[0].toString()), // 质押总人数
        }

        // 获取质押列表
        const perPage = this.state.perPage

        const calls2 = []
        for (let i = adminInfo.userCount - ((page - 1) * perPage) - 1; i >= 0; i--) {
            calls2.push(pool.getUserById(ethers.utils.parseUnits(i + '', 0)))
            if (calls2.length >= perPage) break
        }
        const list = await ethcallProvider.all(calls2)
        console.log(list, 'listttt')

        const arr = list.map(item => {
            // 已提升算力 已领取 质押数 奖励数 用户地址 邀请人
            return {
                id: parseInt(item.id.toString()),
                addr: item.addr,
                inviter: item.inviterAddr,
                inviterCount: item.inviterCount.toString(),
                mintCount: parseInt(item.mintCount.toString()),
                reward: parseFloat(item.reward.toString() / 1e18)
            }
        })
        console.log('第', page, '页信息', list, arr)

        this.setState({
            adminInfo,
            stakeUserList: arr,
        })

    }

    async getInvitorList(page) {
        if (!this.state.account) {
            return
        }
        // 获取下级列表
        const p = await this.initProvider()
        let ethcallProvider = new Provider(p)
        ethcallProvider = await initProvider(ethcallProvider, this.state.chainId)

        // 获取下级总数, 用来分页
        // 按照倒序, 获取下级地址
        // 根据下级地址, 获取信息
        const pool = new Contract(this.state.contract, nftAbi)

        // 获取总数
        // 切片 获取信息
        const calls1 = [pool.inviterListsForUserTotal(this.state.account)]
        const res1 = await ethcallProvider.all(calls1)
        console.log('总数为', res1[0].toString())
        const total = res1[0].toString()
        // 分页
        const perPage = this.state.perPage

        const calls2 = []
        for (let i = total - ((page - 1) * perPage) - 1; i >= 0; i--) {
            calls2.push(pool.inviterListsForUserPage(this.state.account, ethers.utils.parseUnits(i + '', 0)))
            if (calls2.length >= perPage) break
        }
        const list = await ethcallProvider.all(calls2)

        const arr = list.map(item => {
            // 已提升算力 已领取 质押数 奖励数 用户地址 邀请人
            return {
                id: parseInt(item.id.toString()),
                addr: item.addr,
                inviter: item.inviterAddr,
                inviterCount: item.inviterCount.toString(),
                mintCount: parseInt(item.mintCount.toString()),
                reward: parseFloat(item.reward.toString() / 1e18).toFixed(2)
            }
        })
        console.log('第', page, '页信息', list, arr)

        this.setState({
            invitorList: arr,
            invitorTotalCount: parseInt(total),
            perPage
        })




    }



    showMessageModal(info) {
        this.setState({
            swalProps: {
                show: false,
                title: '',
                text: '',
            }
        }, () => {
            this.setState({
                swalProps: {
                    ...info
                }
            })
        })

    }


    generateShareLink() {
        // http://localhost:3000/#/core/0x928D87B7c6Ea72a44E86d72990d199C9905202f9?invitor=0x928D87B7c6Ea72a44E86d72990d199C9905202f9
        return `${this.state.urlPath}?invitor=${this.props.account}`
    }


    getProgressBarWidth() {
        return (((parseInt(this.state.nftInfo?.totalSupply?.toString()) || 0) / parseInt(this.state.nftInfo?.maxSupply?.toString())) * 100).toFixed(2) || 0
    }


    showDoubleColumn() {
        // 展示成两列的样式
        let flag = true // 默认展示成两列
        if (this.state.nftInfo?.isOnlyAdmin === true) { // 仅项目方铸造, 简易页面, 用一列
            flag = false
        }
        // extra 没有值+没开邀请
        if (this.state.ipfsInfo?.extra?.length === 0 && this.state.nftInfo?.enableInvitor === false) {
            flag = false
        }
        return flag
    }


    async showSuccessInfo() {
        const provider = await this.initProvider()
        const c = new ethers.Contract(this.state.contract, nftAbi, provider.getSigner())

        try {
            const myIdList = await c.tokensOfOwner(this.state.account)
            console.log('myIdList', myIdList[myIdList.length - 1].toString())
            if (myIdList.length > 0) {
                const mintId = myIdList[myIdList.length - 1]
                const _uri = await c.tokenURI(mintId)
                const info = await getRemoteUrlContent(_uri)
                const _info = JSON.parse(info)

                window.confetti({
                    particleCount: 100,
                    spread: 160,
                    zIndex: 10000000
                })
                this.setState({
                    mintSuccessInfo: {
                        show: true,
                        id: parseInt(mintId.toString()),
                        url: convertIpfsUrlToGatewayUrl(_info.image),
                        name: _info.name || '',
                        description: _info.description || ''
                    }
                })
            }

        } catch (ee) {
            this.showMessageModal({
                show: true,
                title: this.props.t('成功'),
                text: this.props.t('铸造成功')
            })
        }
    }

    // 生成我的nft的paginate
    generateMyNftsPagination() {

        const pageCount = Math.ceil(this.state.myNftCount / this.state.myNftPerPageCount)
        const page = this.state.myNftPage

        // 从第一页到最后一页
        const arr = []
        let pageIndex = -1
        for (let i = 1; i <= pageCount; i++) {
            arr.push({
                page: i,
                active: i === page,
                ellipsis: false, // 省略号
            })
            if (i === page) {
                pageIndex = i - 1
            }
        }
        // 如果长度超过13, 则需要省略号, 只保留前后5个
        if (pageCount > 13) {
            if (pageIndex < 5) {
                // 前面不需要省略号
                for (let i = 10; i < pageCount; i++) {
                    arr[i].ellipsis = true
                }
            } else if (pageIndex > pageCount - 6) {
                // 后面不需要省略号
                for (let i = 0; i < pageCount - 10; i++) {
                    arr[i].ellipsis = true
                }
            } else {
                // 前后都需要省略号
                for (let i = 0; i < pageCount; i++) {
                    if (i < pageIndex - 5) {
                        arr[i].ellipsis = true
                    } else if (i > pageIndex + 4) {
                        arr[i].ellipsis = true
                    }
                }
            }
        }

        // 去除已省略的部分, 只保留1个
        const arr2 = []
        let ellipsisCount = 0
        arr.forEach(item => {
            if (item.ellipsis) {
                if (ellipsisCount < 1) {
                    arr2.push(item)
                    ellipsisCount++
                }
            } else {
                arr2.push(item)
            }
        })



        console.log(arr2, 'page paginate')
        const res = {
            list: arr2,
            pageCount,
            currentPage: page
        }
        this.setState({
            pagePaginate: res
        })

    }


    render() {

        // document.title = this.props.t('NFT铸造') + ' | Fatsale'
        document.title = 'Gold Pi Network'

        return (
            <section style={{ paddingTop: this.state.nftInfo?.isOnlyAdmin === false ? '40px' : '0px' }} className="staking-area">

                <MintModal
                    invitor={this.state.params['invitor'] || ''}
                    onRef={node => this.stakeModalRef = node} handleClose={() => {
                        this.setState({
                            showMintModal: false
                        })
                    }} ok={() => {
                        // 质押完成
                        this.showSuccessInfo()
                        this.fetchData()
                    }} show={this.state.showMintModal} />

                <Modal centered show={this.state.showTransferOwnershipModal} >
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.t('转移所有权')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ width: '100%' }}>
                            <div>
                                <Form.Control className="ipfs-info-input" value={this.state.tmpNewOwner} onChange={e => {
                                    this.setState({
                                        tmpNewOwner: e.target.value
                                    })
                                }} placeholder={this.props.t('请输入要转移到的地址')} />
                            </div>
                            <div style={{ marginTop: '10px' }}>
                                <div onClick={() => {
                                    if (!isAddress(this.state.tmpNewOwner)) {
                                        this.showMessageModal({
                                            show: true,
                                            title: this.props.t('错误'),
                                            text: this.props.t('输入的地址格式不正确')
                                        })
                                        return
                                    }
                                    if (this.state.tmpNewOwnerLoading) return // 防止重复点击   
                                    this.setState({
                                        tmpNewOwnerLoading: true
                                    }, async () => {
                                        try {
                                            const provider = await this.initProvider()
                                            const pool = new ethers.Contract(this.state.contract, nftAbi, provider.getSigner())
                                            const tx = await pool.transferOwnership(this.state.tmpNewOwner)
                                            await tx.wait()
                                            this.setState({
                                                showTransferOwnershipModal: false
                                            })
                                            this.showMessageModal({
                                                show: true,
                                                text: this.props.t('操作成功')
                                            })
                                        } catch (e) {
                                            console.log(e)
                                            this.handleErr(e)
                                        }

                                        this.setState({
                                            tmpNewOwnerLoading: false
                                        })
                                    })
                                }} className='btn btn-primary'>
                                    {this.state.tmpNewOwnerLoading && <span>
                                        <i style={{ 'fontSize': '16px', marginRight: '10px' }} className="fa fa-spinner loading-css"></i>
                                    </span>}
                                    {this.props.t('确定')}</div>
                                <div style={{ marginLeft: '10px' }} onClick={() => {
                                    this.setState({
                                        showTransferOwnershipModal: false
                                    })
                                }} className='btn btn-primary'>{this.props.t('取消')}</div>
                            </div>
                        </div>
                    </Modal.Body>

                </Modal>
                <SweetAlert2 {...this.state.swalProps} />
                <WhitelistModal
                    poolAddress={this.state.contract}
                    show={this.state.showWhitelistModal}
                    handleClose={() => {
                        this.setState({
                            showWhitelistModal: false
                        })
                    }}
                    ok={() => {
                        this.fetchData()
                        this.setState({
                            showWhitelistModal: false
                        })
                        this.showMessageModal({
                            show: true,
                            title: this.props.t('成功'),
                            text: this.props.t('设置成功'),
                            icon: 'success'
                        })
                    }}
                />
                <InviteConfirmModal
                    invitorRewardPercentList={this.state.nftInfo?.invitorRewardPercentList}
                    fee={this.state.nftInfo?.openInviterFee}
                    poolAddress={this.state.contract}
                    show={this.state.showInviteConfirmModal}
                    open={this.state.nftInfo.enableInvitor}
                    handleClose={() => {
                        this.setState({
                            showInviteConfirmModal: false
                        })
                    }}
                    ok={() => {
                        this.fetchData()
                        this.setState({
                            showInviteConfirmModal: false
                        })
                        this.showMessageModal({
                            show: true,
                            title: this.props.t('成功'),
                            text: this.props.t('设置成功'),
                            icon: 'success'
                        })
                    }}
                />
                <IpfsPanel onRef={node => this.ipfsPanelRef = node} handleClose={() => {
                    this.setState({
                        showIpfsPanel: false
                    })
                }} info={this.state.ipfsInfo} contract={this.state.contract} show={this.state.showIpfsPanel} ok={(p) => {
                    // 调用合约
                    this.fetchData()
                    this.setState({
                        showIpfsPanel: false
                    })
                }} />

                <SuccessModal id={this.state.mintSuccessInfo.id || ''}
                    url={this.state.mintSuccessInfo.url}
                    name={this.state.mintSuccessInfo.name}
                    desc={this.state.mintSuccessInfo.description}
                    show={this.state.mintSuccessInfo.show}
                    handleClose={() => {
                        this.setState({
                            mintSuccessInfo: {
                                show: false,
                                id: 0,
                                url: '',
                                name: '',
                                description: ''
                            }
                        })
                    }}
                />
                <div style={{ width: '100%' }} className="container">
                    <div style={{ width: '100%' }} className="row">
                        <div className={"col-12 " + (!this.showDoubleColumn() ? 'col-md-6 offset-md-3' : 'col-md-7')}>
                            {this.state.nftInfo?.isOnlyAdmin === false && <div className="card no-hover staking-card single-staking">
                                <div className="stake-logo-box">
                                    {this.state.ipfsInfo?.logo && <div className='stake-logo-img stake-logo-img-left'>
                                        <img className='stake-logo-img-im' src={this.state.ipfsInfo.logo} alt='' />
                                    </div>}
                                    <div>
                                        <h3 className="m-0">{this.props.t('铸造')}
                                            &nbsp;  {this.state.nftInfo?.symbol}
                                        </h3>
                                    </div>
                                    {this.state.ipfsInfo?.logo && <div className='stake-logo-img stake-logo-img-right'>
                                        <img className='stake-logo-img-im' src={this.state.ipfsInfo.logo} alt='' />
                                    </div>}
                                </div>
                                <div className="tab-content mt-md-3" id="myTabContent">
                                    <div role="tabpanel">
                                        <div className="staking-tab-content">
                                            <div className="info-box d-flex justify-content-between mt-2">
                                                <div className="info-left">
                                                    <ul className="list-unstyled">
                                                        {this.state.contract && <li onClick={() => {
                                                            copy(this.state.contract)
                                                            this.showMessageModal({
                                                                show: true,
                                                                title: this.props.t('复制成功'),
                                                                html: `<p>${this.state.contract} </p>`,
                                                                icon: 'success'
                                                            })
                                                        }}>
                                                            <strong>
                                                                {this.props.t('合约')}: </strong>
                                                            {this.getShortAddress(this.state.contract)}
                                                            <i className="fa fa-copy user-list-table-copy"></i>
                                                        </li>}
                                                        {this.state.nftInfo?.maxSupply &&
                                                            <li>
                                                                <strong>
                                                                    {this.props.t('总量')}: </strong>
                                                                {this.state.nftInfo.maxSupply.toString()}</li>}
                                                        {this.state.nftInfo?.totalSupply && <li>
                                                            <strong>
                                                                {this.props.t('已铸造')}: </strong> {this.state.nftInfo.totalSupply.toString()}</li>}
                                                        {this.state.nftInfo?.totalSupply && <li><strong>
                                                            {this.props.t('您已铸造')}: </strong>
                                                            {parseInt(this.state.nftInfo.userMintLimit.toString()) > 0 && <span>
                                                                {this.state.nftInfo.userMintCount.toString()}/{this.state.nftInfo.userMintLimit.toString()}
                                                            </span>}
                                                            {parseInt(this.state.nftInfo.userMintLimit.toString()) === 0 && <span>
                                                                {this.state.nftInfo.userMintCount.toString()}
                                                            </span>}
                                                        </li>}

                                                    </ul>
                                                </div>
                                                <div className="info-right d-flex flex-column">
                                                    <ul className="list-unstyled">

                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="item-progress">
                                                <div style={{ height: '1.8rem', borderRadius: '1rem', backgroundColor: '#090A1A' }} className="progress mt-2 mt-md-2">
                                                    <div className="progress-bar" role="progressbar" style={{ width: this.getProgressBarWidth() + '%', backgroundColor: '#0047FF' }} aria-valuenow={parseInt(this.state.nftInfo?.totalSupply?.toString()) || 0} aria-valuemin={0} aria-valuemax={100}>  <span style={{ marginLeft: '10px', marginRight: '10px' }}>  {this.getProgressBarWidth()}% </span>  </div>
                                                </div>
                                                {/* <div className="progress-sale d-flex justify-content-between mt-3">
                                                    <span>{'0'}</span>
                                                    <span>{this.state.nftInfo?.maxSupply?.toString()} {this.state.nftInfo?.symbol}</span>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div className="input-box mt-4 d-flex flex-direction-row align-items-center justify-content-start">

                                    <div onClick={async () => {
                                        if (this.checkNetwork() === false) return

                                        // 检查铸造数量是否超过上限
                                        if (parseInt(this.state.nftInfo.userMintLimit.toString()) > 0) {
                                            if (parseInt(this.state.nftInfo.userMintCount) >= parseInt(this.state.nftInfo.userMintLimit.toString())) {
                                                this.showMessageModal({
                                                    show: true,
                                                    title: this.props.t('错误'),
                                                    text: this.props.t('您的铸造数量已达上限')
                                                })
                                                return
                                            }
                                        }

                                        if (this.state.nftInfo.feeMode === 1) {
                                            // 打开授权框

                                            this.stakeModalRef.setApproveInfo({
                                                amount: this.state.nftInfo.feePerNFT,
                                                tokenDecimals: parseInt(this.state.nftInfo.feeToken.decimals),
                                                stakeContract: this.state.contract,
                                                tokenAddress: this.state.nftInfo.feeToken.address,
                                                currentWallet: this.state.account,
                                                enableInviter: this.state.nftInfo.enableInvitor,
                                                // stakeMax: this.state.stakeMax,
                                                // amountBigNumber: this.state.stakeMax ? this.state.stakeTokenInfo.balanceBigNumber : ethers.utils.parseUnits(this.state.stakeAmountInput + '', parseInt(this.state.stakeTokenInfo.decimals)),
                                                stakeTokenBalanceBigNumber: this.state.nftInfo.feeToken.balanceBigNumber,
                                            }).then(() => {
                                                // 调用流程, 先授权再质押, 中间有错误就停下来
                                                this.stakeModalRef.handleLogic() // 设置数量
                                            })

                                            this.setState({
                                                showMintModal: true
                                            })
                                            return
                                        }

                                        this.setState({
                                            mintLoading: true
                                        })



                                        this.setState({
                                            mintSuccessInfo: {
                                                show: false,
                                                id: 0,
                                                url: '',
                                                name: '',
                                                description: ''

                                            }
                                        })

                                        const provider = await this.initProvider()
                                        try {
                                            const c = new ethers.Contract(this.state.contract, nftAbi, provider.getSigner())

                                            //todo 如果已经开启了邀请返利并且链接中有邀请参数, 则传入邀请者地址
                                            let receiver = zeroAddress
                                            console.log('aaaaa', this.state.nftInfo.enableInvitor, this.state.params['invitor'])
                                            if (this.state.nftInfo.enableInvitor && this.state.params['invitor']) {
                                                if (this.state.params['invitor'].toLowerCase() !== this.state.account.toLowerCase()) {
                                                    receiver = this.state.params['invitor']
                                                }

                                            }
                                            console.log(receiver, '邀请者地址')
                                            const tx = await c.mint(receiver, ethers.utils.parseUnits('1', 0), '', { value: this.state.nftInfo.feePerNFT })
                                            await tx.wait()


                                            this.showSuccessInfo()




                                        } catch (e) {
                                            console.log(e)
                                            this.handleErr(e)
                                        }

                                        this.fetchData()


                                        this.setState({
                                            mintLoading: false
                                        })
                                    }} className="btn">
                                        {this.state.mintLoading && <span>
                                            <i style={{ 'fontSize': '16px', marginRight: '10px' }} className="fa fa-spinner loading-css"></i>
                                        </span>}
                                        {this.props.t('免费铸造')}</div>
                                    <div style={{ marginLeft: '10px', fontSize: '14px', fontWeight: 500 }}>{this.state.nftInfo?.feePerNFT?.toString() / (this.state.nftInfo?.feeMode === 0 ? 1e18 : Math.pow(10, parseInt(this.state.nftInfo?.feeToken.decimals)))} {this.state.nftInfo?.feeMode === 0 ? getChainCurrencySymbolWithChainId(this.state.chainId) : this.state.nftInfo?.feeToken?.symbol}(GAS)</div>
                                </div>

                            </div>}
                            {this.state.isAdmin && <div style={{ width: '100%' }} className='card no-hover staking-card mt-4'>
                                <p>{this.props.t('管理员控制')}</p>
                                <div className='mt-2'>
                                    <div onClick={() => {
                                        this.setState({
                                            showTransferOwnershipModal: true
                                        })
                                    }} style={{ 'width': '100%' }} className="btn btn-bordered-white btn-smaller">
                                        {this.props.t('转移所有权')}
                                    </div>
                                </div>
                                <div className='mt-2'>
                                    <div onClick={() => {
                                        this.setState({
                                            showTransferOwnershipModal: true,
                                            tmpNewOwner: '0x000000000000000000000000000000000000dEaD'
                                        })
                                    }} style={{ 'width': '100%' }} className="btn btn-bordered-white btn-smaller">
                                        {this.props.t('放弃所有权')}
                                    </div>
                                </div>


                                {this.state.nftInfo?.isOnlyAdmin === false && <>
                                    <div className='mt-2'>
                                        <div onClick={() => {
                                            if (this.checkNetwork() === false) return
                                            this.ipfsPanelRef.setIpfsInfo({
                                                ...this.state.ipfsInfo,
                                                // top_bg: 'https://picx.zhimg.com/v2-cba0b89d2bf2d96a1ed26edb5849f804_qhd.jpg?source=172ae18b'
                                            }).then(() => {
                                                this.setState({
                                                    showIpfsPanel: true
                                                })
                                            })

                                        }} style={{ 'width': '100%' }} className="btn btn-bordered-white btn-smaller">
                                            {this.props.t('设置项目信息')}
                                        </div>
                                    </div>
                                    {!this.state.nftInfo?.enableInvitor && <div className='mt-2'>
                                        <div onClick={() => {
                                            if (this.checkNetwork() === false) return
                                            this.setState({
                                                showInviteConfirmModal: true
                                            })
                                        }} style={{ 'width': '100%' }} className="btn btn-bordered-white btn-smaller">
                                            {this.props.t('开启推广邀请')}
                                        </div>
                                    </div>}
                                    {this.state.nftInfo?.enableInvitor && <div className='mt-2'>
                                        <div onClick={() => {
                                            if (this.checkNetwork() === false) return
                                            this.setState({
                                                showInviteConfirmModal: true
                                            })
                                        }} style={{ 'width': '100%' }} className="btn btn-bordered-white btn-smaller">
                                            {this.props.t('设置推广返利等级')}
                                        </div>
                                    </div>}
                                    {this.state.nftInfo?.enableInvitor && <div className='mt-2'>
                                        <div onClick={() => {
                                            if (this.checkNetwork() === false) return
                                            this.setState({
                                                showWhitelistModal: true
                                            })
                                        }} style={{ 'width': '100%' }} className="btn btn-bordered-white btn-smaller">
                                            {this.props.t('设置白名单')}
                                        </div>
                                    </div>}
                                    <div className='mt-2'>
                                        <div onClick={() => {
                                            if (this.checkNetwork() === false) return
                                            copy(window.location.href)
                                            this.showMessageModal({
                                                show: true,
                                                title: this.props.t('复制成功'),
                                                html: `<p>${window.location.href} </p>`,
                                                icon: 'success'
                                            })
                                        }} style={{ 'width': '100%' }} className="btn btn-bordered-white btn-smaller">
                                            {this.props.t('复制铸造页面地址')}
                                        </div>
                                    </div>
                                    {(window.location.href.indexOf('fatsale') > -1 || window.location.href.indexOf('localhost') > -1) && <div className='mt-2'>
                                        <div onClick={() => {
                                            if (this.checkNetwork() === false) return
                                            copy(window.location.href)
                                            this.showMessageModal({
                                                show: true,
                                                title: this.props.t('绑定独立域名'),
                                                html: `<div><p>${this.props.t('平台支持绑定独立域名并部署在单独的服务器中')}</p><p>${this.props.t('此功能需要自行购买域名, 并额外支付部署费用以及后续维护费用')}</p><p>${this.props.t('具体请咨询')} <a target="_blank" href="https://t.me/safu_contract" >${this.props.t('客服')}</a></p></div>`,
                                            })
                                        }} style={{ 'width': '100%' }} className="btn btn-bordered-white btn-smaller">
                                            {this.props.t('绑定独立域名')}
                                        </div>
                                    </div>}
                                </>}


                            </div>}
                        </div>
                        {this.showDoubleColumn() && <div className="col-12 col-md-5">
                            <div className="staking-items mt-4 mt-md-0">
                                {/* 我的邀请链接 */}
                                {this.state.nftInfo?.enableInvitor && <div className='card no-hover staking-card'>
                                    <div style={{ 'position': 'relative' }}>
                                        <input style={{ 'paddingRight': '65px' }} value={this.generateShareLink()} className="unstake-input" type="text" disabled />
                                        <div className="unstake-input-a">{this.props.t('复制')}</div>
                                        <div onClick={() => {

                                            copy(this.generateShareLink())
                                            this.showMessageModal({
                                                show: true,
                                                title: this.props.t('复制成功'),
                                                html: `<p>${this.generateShareLink()}</p>`,
                                                icon: 'success'
                                            })
                                        }} className="unstake-input-a unstake-input-a-mask">

                                        </div>
                                    </div>
                                    <p>{this.props.t('我的邀请链接')}</p>
                                </div>}

                                {this.state.nftInfo?.invitor && <div className='card no-hover staking-card mt-4'>
                                    <div style={{ 'position': 'relative' }}>
                                        <input style={{ 'padding-right': '65px' }} value={this.state.nftInfo.invitor} className="unstake-input" type="text" disabled />
                                        <div className="unstake-input-a">{this.props.t('复制')}</div>
                                        <div onClick={() => {

                                            copy(this.state.nftInfo.invitor)
                                            this.showMessageModal({
                                                show: true,
                                                title: this.props.t('复制成功'),
                                                html: `<p>${this.state.nftInfo.invitor}</p>`,
                                                icon: 'success'
                                            })
                                        }} className="unstake-input-a unstake-input-a-mask">

                                        </div>
                                    </div>
                                    <p>{this.props.t('我的邀请人')}</p>
                                </div>}

                                {this.state.ipfsInfo?.extra?.length > 0 && this.state.ipfsInfo.extra.map(item => {
                                    return <div key={'ext-' + item.name} className='card no-hover staking-card mt-4'>
                                        <h5>{item.name}</h5>
                                        <span>{item.content}</span>
                                    </div>
                                })}
                                {this.state.ipfsInfo.youtube && <div style={{ 'padding': '13px' }} className='mt-4 card no-hover staking-card'>
                                    <iframe className='card-img-top' width="100%" height="260" src={this.state.ipfsInfo.youtube} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                </div>}
                            </div>

                        </div>}
                        {this.state.isAdmin && this.state.nftInfo?.isOnlyAdmin === false && <div style={{ 'width': '100%' }} className='card no-hover staking-card mt-4'>
                            <h5 style={{ 'margin': '0' }}>{this.props.t('管理员数据面板')}</h5>
                            <div style={{ 'position': 'relative' }}>
                                <UserListTable
                                    key={'total-list-table'}
                                    total={this.state.adminInfo.userCount}
                                    // total={100}
                                    perPage={this.state.perPage}
                                    list={this.state.stakeUserList}
                                    changePage={(page) => {
                                        this.getStakeListForAdmin(page)
                                    }}
                                    copy={(addr) => {
                                        copy(addr)
                                        this.showMessageModal({
                                            show: true,
                                            title: this.props.t('复制成功'),
                                            html: `<p>${addr}</p>`,
                                            icon: 'success'
                                        })
                                    }}
                                />
                            </div>
                        </div>}
                        {this.state.invitorTotalCount > 0 && <div style={{ 'width': '100%' }} className='card no-hover staking-card mt-4'>
                            <h5 style={{ 'margin': '0' }}>{this.props.t('我的邀请列表')}</h5>
                            <div className='mt-2 d-flex flex-row' style={{ 'flexWrap': 'wrap' }} >
                                <div>{this.props.t("已邀请用户数")}: {this.state.invitorTotalCount}</div> &nbsp;&nbsp;
                                {this.state.nftInfo?.enableInvitor && <div>{this.props.t("已获得的推荐奖励")}: {this.state.nftInfo.reward} {getChainSymbol(this.state.chainId)}</div>}&nbsp;&nbsp;
                            </div>
                            <div style={{ 'position': 'relative' }}>
                                <UserListTable
                                    key={'user-list-table'}
                                    total={this.state.invitorTotalCount}
                                    // total={100}
                                    perPage={this.state.perPage}
                                    list={this.state.invitorList}
                                    changePage={(page) => {
                                        this.getInvitorList(page)
                                    }}
                                    copy={(addr) => {
                                        copy(addr)
                                        this.showMessageModal({
                                            show: true,
                                            title: this.props.t('复制成功'),
                                            html: `<p>${addr}</p>`,
                                            icon: 'success'
                                        })
                                    }}
                                />
                            </div>
                        </div>}

                        {this.state.myNftList.length > 0 && <div style={{ 'width': '100%' }} className='card no-hover staking-card mt-4'>
                            <div>
                                <h5>{this.props.t('我的NFT')}</h5>
                            </div>
                            <div className='nft-scroll'>
                                {this.state.myNftList.length > 0 && this.state.myNftList.map(item => {
                                    return <NftCard info={item} />
                                })}
                            </div>
                            <div className='mt-2'>
                                {this.state.pagePaginate?.pageCount > 1 && <div className="d-flex flex-row custom-paginate">
                                    {this.state.pagePaginate.currentPage > 1 && <div onClick={() => {
                                        this.getMyNftList(this.state.pagePaginate.currentPage - 1)
                                    }} className="custom-paginate-item">
                                        <i style={{ 'fontSize': '12px' }} className="fas fa-chevron-left"></i>
                                    </div>}
                                    {this.state.pagePaginate.list.map((item, ind) => {
                                        if (item.ellipsis) {
                                            return <div key={'paginate-nft-ellipsis' + ind} className="custom-paginate-item">...</div>
                                        }
                                        return <div key={'paginate-nft' + ind} onClick={() => {
                                            this.getMyNftList(item.page)
                                        }} className={"custom-paginate-item " + (this.state.pagePaginate.currentPage === item.page ? 'custom-paginate-item-active' : '')} >
                                            {item.page}
                                        </div>
                                    })}
                                    {this.state.pagePaginate.currentPage < this.state.pagePaginate.pageCount && <div onClick={() => {
                                        this.getMyNftList(this.state.pagePaginate.currentPage + 1)
                                    }} className="custom-paginate-item">
                                        <i style={{ 'fontSize': '12px' }} className="fas fa-chevron-right"></i>
                                    </div>}
                                </div>}
                            </div>
                        </div>}
                    </div>
                </div>
            </section >
        );
    }
}

// export default StakingOne;

function mapStateToProps(state) {
    const { account, chainId } = state;
    return {
        account,
        chainId
        // text
    };
}

// export default ProjectSingle;
export default connect(mapStateToProps)(withTranslation()(NFTConsoleOne))

